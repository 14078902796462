import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import HeaderCard from './components/headerbar/HeaderCard'
import BannerLeft from './components/bannerbar/BannerLeft'
import BannerRight from './components/bannerbar/BannerRight'
import LeftInfoPanel from './components/infoSection/leftInfoPanel/LeftInfoPanel'
import RightInfoPanel from './components/infoSection/rightInfoPanel/RightInfoPanel'
import { useDispatch, useSelector } from 'react-redux'
import { getTermDashboard } from '../../redux/store/features/dashboard'
import utilServices from '../../services/util-services'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { CanLogin } from 'app/constants/Permissions'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

const Dashboard = () => {
    const { authUser } = useJumboAuth()
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { termSummary } = useSelector((state) => state.dashboard)

    if (!authUser || !CanLogin) navigate('/profile/signout')

    useEffect(() => {
        const fetchDashboardData = async () => {
            setApiCallInProgress(true)
            try {
                if (utilServices.isNullOrUndefined(termSummary?.id)) {
                    dispatch(getTermDashboard({}))
                }
            } catch (error) {
                setApiCallInProgress(false)
                handleError(error)
            }
        }

        fetchDashboardData()
    }, [dispatch, termSummary?.id])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error.message || error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} lg={12}>
                <HeaderCard />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <BannerLeft />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <BannerRight />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <LeftInfoPanel />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <RightInfoPanel />
            </Grid>
        </Grid>
    )
}

export default Dashboard
