import localeCurrency from 'locale-currency'
const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}
const timezone = getTimeZone()
const getCurrencyCode = () => {
    const locale = navigator.language // Detect user's locale
    return localeCurrency.getCurrency(locale) // Returns currency code
}

const currency = getCurrencyCode()
const locale = navigator.language

const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat(locale.languageTag, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
    }).format(amount)

    if (timezone === 'Asia/Karachi') {
        return formattedAmount.replace(/[^-\d.]+/g, '')
    }
    return formattedAmount
}

export default formatCurrency
